import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VFooter } from 'vuetify/lib/components/VFooter';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VFooter,{attrs:{"id":"dashboard-core-footer","absolte":""}},[_c(VContainer,[_c(VRow,{attrs:{"align":"center","no-gutters":""}},[_vm._l((_vm.links),function(link,i){return _c(VCol,{key:i,staticClass:"text-center mb-sm-0 mb-5",attrs:{"cols":"auto"}},[_c('a',{staticClass:"mr-0",attrs:{"href":link.href,"rel":"noopener","target":"_blank"},domProps:{"textContent":_vm._s(link.text)}})])}),_c(VSpacer,{staticClass:"hidden-sm-and-down"}),_c(VCol,{attrs:{"cols":"12","md":"auto"}},[_c('div',{staticClass:"pt-6 pt-md-0 text-center"},[_c('a',{staticClass:"mr-0"},[_vm._v("© 2022 Inpixon.")])])])],2)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }